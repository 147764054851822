import React from "react"

import Layout from "../../../../components/layout"
import Seo from "../../../../components/seo"
import Section from "../../../../components/Section"
import Image from "../../../../components/actions/Image"
export default function Complete() {
  return (
    <Layout>
      <Seo title="Thank you for your request" />
      <Section>
        <Image src="undefined" name="Thank you for your request" />
        {/* <Content /> */}
        <Message />
      </Section>
    </Layout>
  )
}

const Message = () => (
  <div className="p-4 lg:p-8 shadow-xl mb-8">
    <h3 className="text-center">
      Look out for yor customised Ibiza villa recommendations in your email
      soon.
    </h3>
  </div>
)

const Content = () => {
  return (
    <div className="p-4 lg:p-8 shadow-xl mb-8">
      <h2>Your Request</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-6">
        <div className="p-2 bg-gray-100 flex justify-between items-center">
          <strong className="font-serif">Booking Date:</strong>
          <span className="text-xs font-property">12-22-21</span>
        </div>
        <div className="p-2 bg-gray-100 flex justify-between items-center">
          <strong className="font-serif">Passengers:</strong>
          <span className="text-xs font-property">1</span>
        </div>
        <div className="p-2 bg-gray-100 flex justify-between items-center">
          <strong className="font-serif">Your Email:</strong>
          <span className="text-xs font-property">Abc</span>
        </div>
        <div className="p-2 bg-gray-100 flex justify-between items-center">
          <strong className="font-serif">Your Phone:</strong>
          <span className="text-xs font-property">Abc</span>
        </div>
        <div className="p-2 bg-gray-100 flex justify-between items-center">
          <strong className="font-serif">Your Country:</strong>
          <span className="text-xs font-property">Abc</span>
        </div>
        <div className="p-2 bg-gray-100 grid gap md:col-span-2 lg:col-span-3">
          <strong className="font-serif">Requirements</strong>
          <p className="text-xs font-property">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    </div>
  )
}
